import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TbEditCircle } from "react-icons/tb";
import TeamsEdit from '../components/teams/TeamsEdit';
import { useParams } from 'react-router-dom';
import Members from '../components/members/members.jsx';
import Statistic from '../components/statistic.jsx';

function Team() {
    const [team, setTeam] = useState([]);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState({});
    const { id } = useParams();

    const handleEdit = (team) => {
        setEdit(true);
        setSelectedTeam(team);
    }
    
    useEffect(() => {
        console.log(id)
        setLoading(true);
        axios.get(`/api/Teams/${id}`)
            .then((response) => {
                setTeam(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, [id]);

    return (
        <div className='container-fluid mt-3'>
            <div className='fw-semibold rakete-font fs-1 text-center text-uppercase headline'>{team.name}</div>
            <div className='container-fluid'>
                <div className=" shadow-sm rounded-5 my-3 bg-white">
                    <div className=' fs-7 mx-1 pb-2'>
                        <div className='text-end'>
                            <TbEditCircle size={25} className='hover icon-edit mx-3 my-3' onClick={() => handleEdit(team)} />
                        </div>
                        <div className='row d-flex justify-content-center pb-4 px-3'>
                            <div className='col-md-5 text-center'>
                                <img src={`https://server.raketefreiburg.de/uploads/${team.picture}`} className="team-pic rounded card-img-top shadow-sm news-ratio" />
                            </div>
                            <div className='col-md-5 text-center pt-3'>
                                <p className="text-center rakete-font fs-4 text-danger fw-semibold">TRAINING</p>
                                {!team.trainings || team.trainings.length === 0 ? (
                                    <p>Keine Trainingszeiten vorhanden</p>
                                ) : (
                                    <div className='pb-2'>
                                        <div className='text-center fw-semibold rakete-font mb-2 fs-7'>Sommer</div>
                                        <table className="table table-striped">
                                            <thead className='text-center fs-8 rakete-font'>
                                                <tr>
                                                    <th>Tag</th>
                                                    <th>Uhrzeit</th>
                                                    <th>Dauer</th>
                                                    <th>Ort</th>
                                                </tr>
                                            </thead>
                                            <tbody className='text-center'>
                                                {team.trainings
                                                    .filter(training => training.season)
                                                    .sort((a, b) => {
                                                        const weekdays = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
                                                        return weekdays.indexOf(a.day) - weekdays.indexOf(b.day);
                                                    })
                                                    .map((training, trainingIndex) => (
                                                        <tr key={trainingIndex}>
                                                            <td>{training.day}</td>
                                                            <td>{training.time} Uhr</td>
                                                            <td>{training.duration} Std</td>
                                                            <td>{training.location}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        <div className='text-center fw-semibold rakete-font mb-2 fs-7'>Winter</div>
                                        <table className="table table-striped">
                                            <thead className='text-center fs-8 rakete-font text-uppercase'>
                                                <tr>
                                                    <th>Tag</th>
                                                    <th>Uhrzeit</th>
                                                    <th>Dauer</th>
                                                    <th>Ort</th>
                                                </tr>
                                            </thead>
                                            <tbody className='text-center'>
                                                {team.trainings
                                                    .filter(training => !training.season)
                                                    .sort((a, b) => {
                                                        const weekdays = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
                                                        return weekdays.indexOf(a.day) - weekdays.indexOf(b.day);
                                                    })
                                                    .map((training, trainingIndex) => (
                                                        <tr key={trainingIndex}>
                                                            <td>{training.day}</td>
                                                            <td>{training.time} Uhr</td>
                                                            <td>{training.duration} Std</td>
                                                            <td>{training.location}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                {/* <div className='text-center pt-3'>
                                                    <p className="text-center rakete-font fs-4 rakete-rot fw-semibold">ABOUT US</p>
                                                    {team.about}
                                                </div>
                                                <div className='text-center pt-3'>
                                                    <p className="text-center rakete-font fs-4 rakete-rot fw-semibold">MOTTO</p>
                                                    <p className='rakete-font fw-semibold'>{team.slogan}</p>
                                                </div> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {id && <Statistic id={id} />}
            <Members id={team.number} />     
            <TeamsEdit show={edit} onHide={() => setEdit(false)} team={selectedTeam} />
        </div>
    )
}

export default Team;
