import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { RiDeleteBinLine } from "react-icons/ri";
import dayjs from 'dayjs';
import { HiOutlinePlus } from "react-icons/hi";

function Tasks() {
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleteId, setDeleteId] = useState("");

    useEffect(() => {
        setLoading(true);
        axios.get(`/api/Task`)
            .then((response) => {
                setTasks(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    const handleDelete = (id) => {
        setDeleteId(id);
        setLoading(true);

        axios
            .delete(`api/Task/${id}`)
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    return (
            <div className="shadow-lg rounded-4 py-3 my-3 bg-white">
                <div className='row mt-2'>
                    <div className='col-4 ms-1'></div>
                    <div className='col-4 fw-semibold rakete-font fs-5 text-center text-danger text-uppercase'>To Do´s</div>
                    <div className='col-3 fw-semibold rakete-font fs-5 text-danger text-uppercase text-end me-1'>
                        <HiOutlinePlus size={25} className='mb-3 hover icon-edit'/>
                    </div>
                    </div>
                    <div className='fs-7'>
                            {tasks && tasks.length > 0 ? tasks.map((task, index) => (
                                <div key={task._id} className="rounded-5 row py-2 align-items-center events justify-content-center mx-2">
                                    <div className='row d-flex justify-content-center align-items-center my-3'>
                                        <div className='col'>
                                            <div className='row align-items-center'>
                                                <div className='col-auto fs-8 fw-semibold rakete-font'>
                                                    {dayjs(task.createdAt).format("DD.MM.YY HH:mm")}
                                                </div>
                                                <div className='col-auto fs-8 fw-semibold rakete-font text-uppercase'>
                                                    {task.topic == 0 ? "Kontakt" : task.topic == 1 ? "Mitglied" : task.topic == 2 ? "Sponsoring" : "Turnier"}
                                                </div>
                                                <div className='col-md-6 fw-semibold text-wrap'>
                                                    {task.firstname} {task.lastname} {task.topic == 0 ? "hat eine Frage" : task.topic == 1 ? "möchte Rakete beitreten" : task.topic == 2 ? "möchte uns unterstützen" : "hat ein Team angemeldet"}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2 text-end">
                                            <RiDeleteBinLine size={20} className='hover icons mx-1 my-1' onClick={() => handleDelete(task._id)} />
                                        </div>
                                    </div>
                                </div>
                            )) : (
                                    <div className='rakete-font fs-7 ms-3 text-start text-secondary ps-2 py-2 text-center'>Es gibt nichts zu tun</div>
                            )}
                    </div>
            </div>
    )
}

export default Tasks;
