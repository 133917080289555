import { Link, NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import { useEffect, useState } from 'react';
import { useAuth } from '../provider/auth';
import axios from 'axios';
import MemberDetail from './members/memberDetail';
import NavDropdown from 'react-bootstrap/NavDropdown';

function Navibar() {
  const [expanded, setExpanded] = useState(false);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState([]);
  const [memberDetail, setMemberDetail] = useState(false);

  const closeNav = () => setExpanded(false);

  const { user, logout, rights } = useAuth();

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/members/${user}`)
      .then((response) => {
        setUserData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
      axios
      .get(`/teams/ids`)
      .then((response) => {
        setTeams(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [user]);

  return (
    <Navbar expanded={expanded} onToggle={setExpanded} className="navbar sticky-top shadow-sm rounded-bottom-5 no-text-cursor" expand="md" bg="white" id="sidebar">
      <Container fluid>
        <Navbar.Brand>
          <div className="d-flex align-items-center">
            <Link to='/' className="nav-link" onClick={closeNav}>
              <img src={"https://server.raketefreiburg.de/logo/logo-ohne-schriftzug.png"} alt="Logo" height="50px" />
            </Link>
            <Link to='/' className='navbar-brand rakete-font logo-nav-text text-dark' onClick={closeNav}>
              <b className="rakete-rot">CLUB</b>
              <b>BOARD</b>
            </Link>
          </div>
        </Navbar.Brand>

        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-md-center">
          <Nav className="text-dark">
            <Nav.Item>
              <NavLink to='/' className="nav-link" onClick={closeNav}>Dashboard</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to='/events' className="nav-link" onClick={closeNav}>Events</NavLink>
            </Nav.Item>
            {rights === 1 && <Nav.Item>
              <NavLink to='/news' className="nav-link" onClick={closeNav}>News</NavLink>
            </Nav.Item>}
            <Nav.Item>
              <NavLink to='/members' className="nav-link" onClick={closeNav}>Mitglieder</NavLink>
            </Nav.Item>
            <NavDropdown title="Teams" id="basic-nav-dropdown">
            {teams && teams.map((team, index) => (
              <NavDropdown.Item key={index}>
                  <Nav.Item>
                    <NavLink to={`/team/${team.id}`} className="nav-link" onClick={closeNav}>{team.name}</NavLink>
                  </Nav.Item>
                  </NavDropdown.Item>
              
            ))}
                </NavDropdown>
            {rights === 2 &&
              <NavDropdown title="Website" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.2">
                  <Nav.Item>
                    <NavLink to='/news' className="nav-link" onClick={closeNav}>News</NavLink>
                  </Nav.Item>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <NavLink to='/club' className="nav-link" onClick={closeNav}>Verein</NavLink>
                </NavDropdown.Item>
              </NavDropdown>}
          </Nav>
        </Navbar.Collapse>

        <div className='nav-right d-flex justify-content-center'>
          <Dropdown className="avatar-dropdown me-3">
            <Dropdown.Toggle as="img" className="avatar-nav" src={`https://server.raketefreiburg.de/uploads/${userData.picture}`} alt={`${userData.forename}`} />
            <Dropdown.Menu className="custom-dropdown-menu text-center">
              <img className="avatar-middle mx-auto" src={`https://server.raketefreiburg.de/uploads/${userData.picture}`} alt={`${userData.forename}`} />
              <p className='rakete-font rakete-rot fs-7 mt-1 text-uppercase'>{userData.forename} {userData.lastname}</p>
              <Dropdown.Item as="button" className="sign-button text-uppercase rounded fw-semibold border-0 fs-7 my-2" onClick={() => (setMemberDetail(true))}>Profil anzeigen</Dropdown.Item>
              <button className="sign-button login-button text-uppercase rounded fw-semibold border-0 fs-7 mx-auto" type="submit" onClick={handleLogout}>Logout</button>
            </Dropdown.Menu>
          </Dropdown>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="me-1" />
        </div>
      </Container>
      <MemberDetail show={memberDetail} onHide={() => (setMemberDetail(false))} member={userData} />
    </Navbar>

  );
}

export default Navibar;
