import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TbEditCircle } from "react-icons/tb";
import { IoMdPlanet } from "react-icons/io";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { MdMessage } from "react-icons/md";

function Statistic({ id }) {
    const [team, setTeam] = useState([]);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [statistic, setStatistic] = useState([]);

    if (id) {
        useEffect(() => {
            getData(id);
        }, [id]);
    }

    const getData = (id) => {
        axios.post(`/statistic/Team/${id}`)
            .then((response) => {
                setStatistic(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }



    return (
        <div className='my-5'>
            <div className='container-fluid'>
                <div className=" shadow-sm rounded-5 my-3 bg-light">
                    <div className='fs-3 rakete-font text-center py-3 fw-semibold py-3 text-danger'>STATISTIK</div>
                    <div className='row justify-content-center align-items-center pb-3'>
                        <div className='col-md my-3'>
                            <div className='row justify-content-center align-items-center'>
                                <div className='col-4 text-center border-end'>
                                <div className='rakete-font text-center text-uppercase fw-bold fs-6'>Total</div>
                                    <div className=' text-center rakete-font text-uppercase fw-bold fs-3'>{statistic.totalMembers}</div>
                                </div>
                                <div className='col-4 text-center border-end'>
                                <div className='rakete-font text-center text-uppercase fw-semibold fs-6 text-success'>Aktiv</div>
                                <div className=' text-center rakete-font text-uppercase fw-semibold fs-3 text-success'>{statistic.active}</div>
                                </div>
                                <div className='col-4 text-center border-end'>
                                    <div className='rakete-font text-center text-uppercase fw-semibold fs-6 '>Passiv</div>
                                    <div className=' text-center rakete-font text-uppercase fw-semibold fs-3 '>{statistic.passive}</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md my-3'>
                            <div className='row justify-content-center align-items-center'>
                                <div className='col-4 text-center border-end'>
                                    <div className='rakete-font text-center text-uppercase fw-semibold fs-6'><MdMessage size = {22} /></div>
                                    <div className=' text-center rakete-font text-uppercase fw-semibold fs-3'>{statistic.requests}</div>
                                </div>
                                <div className='col-4 text-center border-end'>
                                    <div className='rakete-font text-center text-uppercase fw-semibold fs-6 text-success'><FaArrowRight size={22} /></div>
                                    <div className=' text-center rakete-font text-uppercase fw-semibold fs-3 text-success'>{statistic.entries}</div>
                                </div>
                                <div className='col-4 text-center border-end'>
                                    <div className='rakete-font text-center text-uppercase fw-semibold fs-6 text-danger'><FaArrowLeft size={22} /></div>
                                    <div className=' text-center rakete-font text-uppercase fw-semibold fs-3 text-danger'>{statistic.departures}</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md my-3'>
                        <div className='rakete-font text-center text-uppercase fw-semibold fs-6'>Altersdurchschnitt</div>
                                    <div className=' text-center rakete-font text-uppercase fw-semibold fs-3'>{statistic.avgAge}</div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Statistic;
